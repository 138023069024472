.agora-container {
  width: 100%;
  height: 600px;
  position: relative;
  color: white;
  background-color: black;
}

.agora-overlay-no-streams {
  background-color: #444444;
}

.agora-stream-div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.agora-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.agora-overlay-playing {
  animation-name: fadeOut;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
}

.agora-overlay-playing:hover {
  animation-name: fadeIn;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
}
