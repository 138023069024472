.editable .form-control:not(.show-border) {
  border-width: 0px !important;
}

.editable .form-control:not(.show-border):hover,
.editable .form-control:not(.show-border):focus,
.editable .error {
  border-width: 1px !important;
}

.editable .error {
  border-color: red !important;
}

.editable .editing {
  border-color: black;
}

.editable .valid {
  border-color: green;
}

.editable .invalid {
  border-color: red;
}
