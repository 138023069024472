.fadeable {
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  text-align: right;
  font-size: 40pt;
  transition: opacity 1.5s;
  opacity: 0;
}

.fadeable.faded-in {
  opacity: 1;
}
