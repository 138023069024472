/* -------------------------------- */
/* General Page Styles */
/* -------------------------------- */
.rideshare-leadgen-page {
  font-family: 'Arial', sans-serif;
  color: #3a4047;
  line-height: 1.6;
}

/* -------------------------------- */
/* Header Section */
/* -------------------------------- */
.header-section {
  position: relative;
  margin-bottom: 60px;
  padding: 20px 10px; /* Added top padding */
}

.header-image-container {
  position: relative;
  margin: 0 auto;
  width: 100%; /* Set width to 100% to use the full width of the container */
  max-width: 1200px; /* Add a max-width to control the overall width */
  height: 500px;
  overflow: hidden;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 30px;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 20%;
  border-radius: 5px;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.header-logo-container {
  position: relative;
  top: -30px; /* Adjusted to add space for the logo */
  margin-bottom: -20px; /* Reduce spacing after the logo */
}

.header-logo {
  max-width: 160px; /* Adjusted logo size */
  height: auto;
  margin-bottom: 10px;
}

.header-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.header-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

.header-cta {
  padding: 15px 40px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #00b6d9;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid #00b6d9;
  font-weight: 600;
  animation: fadeInUp 1.4s ease-out;
  box-shadow: 0 4px 15px rgba(0, 182, 217, 0.3);
}

.header-cta:hover {
  background-color: rgba(0, 182, 217, 0.1);
  border-color: #fff;
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 182, 217, 0.4);
}

/* Add animation keyframes if not already present */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* -------------------------------- */
/* Service Section Styles */
/* -------------------------------- */
.service-section {
  margin: 60px 0;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.alt-background {
  background-color: #f0f2f5;
  border-radius: 5px;
}

.service-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

.text-content {
  flex: 1;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-content img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.service-section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #00b6d9;
}

.service-section h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #343a40;
}

.service-section ul {
  padding-left: 20px;
  list-style-type: disc;
  text-align: left;
  margin-bottom: 30px;
}

.service-section li {
  margin-bottom: 10px;
  line-height: 1.6;
}

/* -------------------------------- */
/* Why Choose Wave Section Styles */
/* -------------------------------- */
.why-choose-section {
  margin: 60px 0;
  padding: 40px;
  background-color: #f0f2f5;
  border-radius: 5px;
}

.why-choose-section h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.why-choose-section ul {
  padding-left: 20px;
  list-style-type: disc;
  text-align: left;
}

.why-choose-section li {
  margin-bottom: 10px;
  line-height: 1.6;
}

/* -------------------------------- */
/* Contact Section Styles */
/* -------------------------------- */
.contact-section {
  text-align: center;
  padding: 80px 0;
  background: linear-gradient(135deg, #00b6d9 0%, #0090aa 100%);
  color: #ffffff;
  position: relative;
}

.contact-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.contact-logo {
  max-width: 200px;
  margin-bottom: 30px;
  filter: brightness(0) invert(1);
  position: relative;
  z-index: 1;
}

.highlighted-contact {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 20px 0;
  color: #ffffff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.contact-link {
  color: #ffffff;
  text-decoration: underline;
  transition: all 0.3s ease;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-weight: 700;
}

.contact-link:hover {
  color: #ffffff;
  opacity: 0.9;
  text-decoration: none;
}

/* -------------------------------- */
/* Call to Action Section Styles */
/* -------------------------------- */
.cta-section {
  margin: 60px 0;
  padding: 40px;
  text-align: center;
  background-color: #f0f2f5;
  border-radius: 5px;
}

.cta-section p {
  font-size: 18px;
  margin-bottom: 20px;
}

.cta-section .btn-primary {
  font-size: 18px;
  padding: 10px 20px;
}

/* -------------------------------- */
/* Carousel Section Styles */
/* -------------------------------- */
.carousel-section {
  margin: 60px 0;
  padding: 40px;
  text-align: center;
  background-color: #f0f2f5;
  border-radius: 5px;
}

.carousel-section h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.carousel-video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: 5px;
  margin: 0 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.carousel-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.carousel-video-wrapper:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.carousel .react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -------------------------------- */
/* Responsive Adjustments */
/* -------------------------------- */
@media (max-width: 768px) {
  .header-title {
    font-size: 28px;
  }

  .header-subtitle {
    font-size: 16px;
  }

  .service-content {
    flex-direction: column;
    text-align: center;
  }

  .service-section h2 {
    font-size: 24px;
  }

  .service-section h3 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .header-logo {
    max-width: 120px;
    margin-bottom: 15px;
  }

  .header-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .header-subtitle {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .header-cta {
    font-size: 16px;
    padding: 8px 16px;
  }

  .service-section h2 {
    font-size: 20px;
  }

  .service-section h3 {
    font-size: 18px;
  }
}
