.professional-services-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  background-color: #ffffff;
}

/* Hero Section */
.hero-section {
  text-align: center;
  margin-bottom: 5rem;
  padding: 2rem 1rem;
}

.hero-logo {
  width: 280px; /* More reasonable size */
  height: auto;
  margin-bottom: 3rem;
  object-fit: contain;
}

.hero-title {
  font-size: 2.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
  line-height: 1.2;
}

.hero-subtitle {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto;
}

/* Services Grid */
.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-bottom: 5rem;
  padding: 0 1rem;
}

.service-card {
  background: #ffffff;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05),
              0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 182, 217, 0.2);
}

.service-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
  color: #00b6d9;
  line-height: 1.3;
}

.service-description {
  color: #4a5568;
  line-height: 1.7;
  font-size: 1.05rem;
}

/* Contact Section */
.contact-section {
  text-align: center;
  padding: 5rem 1rem;
  background: linear-gradient(135deg, #00b6d9 0%, #0090aa 100%);
  border-radius: 12px;
  margin-top: 3rem;
  color: #ffffff;
}

.contact-section h2 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.contact-section p {
  color: #4a5568;
  margin-bottom: 2.5rem;
  font-size: 1.15rem;
  line-height: 1.6;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease;
  letter-spacing: 0.3px;
  gap: 0.5rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.8);
}

.btn-primary:hover {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .professional-services-page {
    padding: 3rem 1.5rem;
  }

  .hero-logo {
    width: 220px; /* Smaller on mobile */
  }

  .hero-title {
    font-size: 2.25rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .service-card {
    padding: 2rem;
  }

  .contact-section {
    padding: 3rem 1.5rem;
  }

  .contact-section h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .hero-logo {
    width: 180px; /* Even smaller on very small screens */
  }

  .hero-title {
    font-size: 2rem;
  }

  .btn {
    padding: 0.875rem 2rem;
    font-size: 1rem;
  }
}
