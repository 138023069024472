/* -------------------------------- */
/* General Page Styles */
/* -------------------------------- */
.microtransit-page {
  font-family: 'Arial', sans-serif;
  color: #3a4047;
  line-height: 1.6;
}

/* -------------------------------- */
/* Header Section */
/* -------------------------------- */
.header-section {
  position: relative;
  margin-bottom: 60px;
  padding: 20px 10px; /* Added top padding */
}

.header-image-container {
  position: relative;
  margin: 0 auto;
  width: 100%; /* Set width to 100% to use the full width of the container */
  max-width: 1200px; /* Add a max-width to control the overall width */
  height: 500px;
  overflow: hidden;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 30px;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 20%;
  border-radius: 5px;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.header-logo-container {
  position: relative;
  top: -30px; /* Adjusted to add space for the logo */
  margin-bottom: -20px; /* Reduce spacing after the logo */
}

.header-logo {
  max-width: 160px; /* Adjusted logo size */
  height: auto;
  margin-bottom: 10px;
}

.header-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.header-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

.header-cta {
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
}

/* -------------------------------- */
/* Service Section Styles */
/* -------------------------------- */
.service-section {
  margin: 60px 0;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left; /* Ensure text is left-aligned */
}

.alt-background {
  background-color: #f0f2f5;
  border-radius: 5px;
}

.service-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

.text-content {
  flex: 1;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-content img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* -------------------------------- */
/* Market Needs Section Styles */
/* -------------------------------- */
.market-needs-section {
  margin: 60px 0;
  padding: 40px;
  text-align: center;
}

.market-needs-section h2 {
  font-size: 32px;
  color: #00b6d9; /* Changed color to #00b6d9 */
  text-align: center; /* Centered the heading */
  margin-bottom: 40px;
}

.market-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.market-city {
  flex: 1 1 45%;
  padding: 30px; /* Added more padding */
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left; /* Override inherited center alignment */
}

.market-city.alt-background {
  background-color: #f0f2f5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added drop shadow */
}

/* -------------------------------- */
/* Contact Section Styles */
/* -------------------------------- */
.contact-section {
  text-align: center;
  padding: 40px 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.contact-section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #343a40;
}

.highlighted-contact {
  font-size: 18px;
  color: #6c757d;
}

.contact-link {
  color: #00b6d9;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

/* -------------------------------- */
/* Form Section Styles */
/* -------------------------------- */
.form-section {
  margin: 60px 0;
  padding: 40px;
  text-align: center;
}

.form-section h2 {
  font-size: 32px;
  color: #00b6d9;
  margin-bottom: 40px;
}

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe-container iframe {
  width: 100%;
  max-width: 800px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* -------------------------------- */
/* Responsive Adjustments */
/* -------------------------------- */
@media (max-width: 768px) {
  .header-title {
    font-size: 28px;
  }

  .header-subtitle {
    font-size: 16px;
  }

  .service-content {
    flex-direction: column;
    text-align: center;
  }

  .service-section h2 {
    font-size: 24px;
  }

  .service-section h3 {
    font-size: 20px;
  }

  .market-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .market-city {
    flex: 1 1 100%;
    max-width: 600px;
  }

  .iframe-container iframe {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .header-logo {
    max-width: 120px;
    margin-bottom: 15px;
  }

  .header-title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .header-subtitle {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .header-cta {
    font-size: 16px;
    padding: 8px 16px;
  }

  .service-section h2 {
    font-size: 20px;
  }

  .service-section h3 {
    font-size: 18px;
  }

  .market-needs-section h2 {
    font-size: 24px;
  }

  .iframe-container iframe {
    height: 1600px; /* Adjusted height for smaller screens */
  }
}