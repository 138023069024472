/* TABLE */

.table-responsive {
  margin-top: 2rem;
}

.accepted {
  color: #888888 !important;
  font-style: italic;
}

/* SEPARATORS */

hr {
  margin: 1.5rem 0rem;
}

/* FILE LINKS */

.file-link {
  display: inline-block;
  /* width: 7rem; */
  /* background: #f5f5f6; */
  border: 1px solid #eee;
  border-radius: 0.25rem;
  padding: 1rem;
}

.file-link img {
  /* margin: 0.5rem; */
  height: 7rem;
  max-height: 7rem;
  max-width: 7rem;
}

.file-link a {
  display: block;
}

.file-link p {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .file-link p,
  .file-link a {
    width: 7rem;
    height: 7rem;
    line-height: 7rem;
    text-align: center;
  }
}
