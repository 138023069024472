.main-content {
  margin-top: 1em;
  /* padding: 0 1.5rem 1.5rem 0; */
  /* margin: 0 auto; */
  /* min-height: 100%; */
  max-width: 1400px;
}

.form-control.limited-width {
  max-width: 300px;
}

.clickable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

hr.hr-right {
  max-width: 300px;
  border-width: 2px;
  margin: 2rem 0 1.25rem auto;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #eee;
}

img.profile-image {
  object-fit: contain;
}

.profile-image-small {
  width: 28px;
  height: 28px;
  margin: 0 0.5rem;
}

.dmf-map {
  height: 350px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

div.receipt-image {
  display: inline-block;
  height: 7rem;
}

div.receipt-image img {
  width: auto;
  height: 100%;
}
