html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* LG */

@media (max-width: 992px) {
  .d-none-under-lg {
    display: none !important;
  }
}

.limited-width {
  max-width: 900px !important;
}

.block-link,
.block-link:hover {
  width: 100%;
  display: block;
  padding: 0.7rem 1rem;
  color: inherit;
  text-decoration: none;
}