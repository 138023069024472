// Bootstrap V4
// https://getbootstrap.com/docs/4.6/getting-started/introduction/

// Colors //

$white: #fff !default;
$gray-100: #f6f6f6;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #3a4047;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #00b6d9 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #fc3469;
$red: #ff0000;
$orange: #fd7e14 !default;
$yellow: #fad63c;
$green: #09b8b8;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $blue;
$secondary: $gray-600;
$success: $teal !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $pink;
$light: $gray-100 !default;
$dark: $gray-800 !default;

// $body-bg: $light;
$body-color: $dark;

$theme-colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

// End Colors //

// .roboto {
//   font-family: "Roboto", sans-serif;
// }

//Card
// $card-spacer-y: 1rem;
// $card-spacer-x: 2rem;
$card-cap-bg: $white;
// $card-cap-bg: rgba($black, 0.02);
$card-bg: $white;

// Type
// $hr-border-color: rgba($black, 0.05);
// $hr-margin-y: 2rem;
// $hr-border-width: 1px;

// Buttons
// $btn-padding-x: 1.25rem;
// $btn-border-radius: 1.25rem;
// $btn-border-radius-lg: 2rem;

// Navbar
$navbar-toggler-padding-x: 0.5rem;
$navbar-toggler-border-radius: 0.25rem;
$navbar-dark-toggler-border-color: rgba($white, 0.2);

// Forms
// $input-border-radius: 0rem;

// $input-border-color: transparent;
// $input-border-width: 0rem;
$form-group-margin-bottom: 2rem;

// Typography
$headings-margin-bottom: 0.75rem;

//Link
$link-color: $gray-700;
// $link-hover-decoration: none;

$tooltip-max-width: 900px;
$tooltip-color: $dark;
$tooltip-bg: $white;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.6rem;
.tooltip-inner {
  box-shadow: 0.2rem 0.2rem 1rem #0000003a;
}

$border-radius: 0.25rem * 1.5;
$border-radius-lg: 0.3rem * 1.5;
$border-radius-sm: 0.2rem * 1.5;

.rounded-xl {
  border-radius: 1rem !important;
}

.rounded-right-xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-bottom-xl {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

// @media (min-width: 992px) {
//   .rounded-xl {
//     border-radius: 1rem !important;
//   }
//   .rounded-bottom-xl {
//     border-bottom-right-radius: 1rem !important;
//     border-bottom-left-radius: 1rem !important;
//   }
// }

$grid-gutter-width: 1rem;

// switches

$custom-control-indicator-size: 1.25rem;
// $custom-switch-width: 2.5rem;
// $custom-switch-indicator-border-radius: 0rem;// $custom-control-indicator-size / 2 !default;
// $custom-switch-indicator-size: 1rem;
//  calc(
//     #{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}
// ) !default;

// $link-decoration: iniital !default;

// $spinner-width: 2rem !default;
// $spinner-height: $spinner-width !default;
$spinner-border-width: 0.2em !default;

// $spinner-width-sm:        1rem !default;
// $spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: 0.17em !default;

// remove modal header+footer borders

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

// nav-pills

// $nav-pills-border-radius:           $border-radius !default;
// $nav-pills-link-active-color: $primary;
// $nav-pills-link-active-bg: none;
// .nav-pills .nav-link.active {
//     border: 1px solid $primary;
// }

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Optional
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

// OUR CUSTOM STUFF
@import "apple";
@import "general";
@import "borders";
@import "slider";
@import "images";
@import "colors";
@import "tables";
@import "fix";
@import "text";
@import "googlemaps";
@import "forms";
@import "cardselect";
