.google-map-2 {
  position: absolute;
}

.track-my-location {
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.3);
}
