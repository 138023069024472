@each $color, $value in $colors {
  .text-#{$color} {
    color: $value !important;
  }
}

.bg-white-85 {
  background-color: rgba($color: #ffffff, $alpha: 0.85);
}

.bg-primary-light {
  background-color: #9dddea;
}

// .bg-primary-25 {
//     background-color: rgba($color: $primary, $alpha: 0.25);
// }

.border-success-pulse {
  border-width: 1.5px;
  border-style: solid;
  animation-duration: 1.25s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: border-pulse-success;
}

@keyframes border-pulse-success {
  0% {
    border-color: $success;
  }
  100% {
    border-color: desaturate(lighten($success, 50), 25);
  }
}

div.loading-animation {
  animation-duration: 1s;
  // animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  // animation-fill-mode: forwards;
  animation-name: loading-animation-background;
  background: linear-gradient(90deg, $light, darken($light, 10), $light);
  background-size: 200% 100%;
}

@keyframes loading-animation-background {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% 0%;
  }
}

@each $gray, $value in $grays {
  .text-gray-#{$gray} {
    color: $value !important;
  }
}
@each $number, $value in $grays {
  .badge-gray-#{$number} {
    @include badge-variant($value);
  }
}

@each $gray, $value in $grays {
  .bg-gray-#{$gray} {
    background-color: $value !important;
  }
}

@each $gray, $value in $grays {
  .border-gray-#{$gray} {
    border-color: $value !important;
  }
}

.bg-primary-to-success {
  background: linear-gradient(90deg, $primary, $teal);
}

.bg-light-trans {
  background-color: #fffffff6;
}

.bg-dark-trans {
  background-color: #343a4050;
}

.bg-blur {
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);
}

// media query backgrounds

// @each $color, $colorValue in $theme-colors {
//     @each $breakpoint in map-keys($grid-breakpoints) {
//         @include media-breakpoint-up($breakpoint) {
//             $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//             @each $displaysValue in $displays {
//                 .bg#{$infix}-#{$displaysValue}-#{$color} {
//                     background-color: $colorValue !important;
//                 }
//             }
//         }
//     }
// }

// @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//         $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//         @each $displaysValue in $displays {
//             @each $color, $colorValue in $theme-colors {
//                 .bg#{$infix}-#{$displaysValue}-#{$color} {
//                     background-color: $colorValue !important;
//                 }
//             }
//         }
//     }
// }

@each $color, $value in $theme-colors {
  .bg-hover-#{$color}:hover {
    background-color: $value !important;
  }
}
