.track-map-contents-button {
  /* width: 24px; */
  /* height: 38px; */
  padding: 8px 12px;
  position: absolute;
  bottom: 24px;
  right: 68px;
  z-index: 1000;
  background-color: white;
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  /* border: 2px solid #eee; */
  /* border-radius: 2px; */
  /* cursor: pointer; */
}
