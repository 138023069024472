/* -------------------------------- */
/* General Styles */
/* -------------------------------- */
.catering-delivery-page {
    padding: 0;
    background-color: #ffffff;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

/* -------------------------------- */
/* Header Section */
/* -------------------------------- */
.header-section {
    text-align: center;
    margin-bottom: 0;
    position: relative;
}

.header-image-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 65vh;
    min-height: 500px;
    overflow: hidden;
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.1);
    transition: transform 0.3s ease-out;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
}

.header-logo {
    max-width: 240px;
    height: auto;
    margin-bottom: 30px;
    animation: fadeInDown 1s ease-out;
}

.header-title {
    font-size: 3.5rem;
    color: #fff;
    margin-bottom: 20px;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    animation: fadeInUp 1s ease-out;
}

.header-subtitle {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 30px;
    max-width: 800px;
    line-height: 1.4;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    animation: fadeInUp 1.2s ease-out;
}

.header-cta {
    padding: 15px 40px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #00b6d9;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s ease;
    border: 2px solid #00b6d9;
    font-weight: 600;
    animation: fadeInUp 1.4s ease-out;
    box-shadow: 0 4px 15px rgba(0, 182, 217, 0.3);
}

.header-cta:hover {
    background-color: rgba(0, 182, 217, 0.1);
    border-color: #fff;
    color: #fff;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 182, 217, 0.4);
}

/* -------------------------------- */
/* Section Headers */
/* -------------------------------- */
.section-header {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    padding-top: 20px;
}

.section-header h2 {
    font-size: 2.5rem;
    color: #1a1a1a;
    font-weight: 700;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
}

.section-header h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: #00b6d9;
    border-radius: 2px;
}

.section-header p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}

/* -------------------------------- */
/* Use Cases Section */
/* -------------------------------- */
.use-cases-section {
    padding: 40px 0;
    background-color: #ffffff;
    position: relative;
}

.use-cases {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
}

.use-case {
    width: calc(50% - 20px);
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.use-case:hover {
    transform: translateY(-5px);
}

.use-case h3 {
    color: #1a1a1a;
    font-size: 1.8rem;
    margin: 25px 0;
    font-weight: 600;
}

.use-case-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.use-case:hover .use-case-image {
    transform: scale(1.05);
}

.use-case-content {
    padding: 20px;
}

.use-case ul {
    padding-left: 20px;
    margin-top: 20px;
}

.use-case li {
    margin-bottom: 15px;
    color: #4a4a4a;
    line-height: 1.6;
    position: relative;
    padding-left: 25px;
}

.use-case li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #00b6d9;
    font-weight: bold;
}

/* -------------------------------- */
/* Commitment Section */
/* -------------------------------- */
.commitment-section {
    padding: 80px 0;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.commitment-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 40px;
}

.commitment-item {
    background: #ffffff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.commitment-item:hover {
    transform: translateY(-5px);
}

.commitment-item i {
    font-size: 2rem;
    color: #00b6d9;
    margin-bottom: 20px;
}

.commitment-item h3 {
    color: #1a1a1a;
    font-size: 1.4rem;
    margin-bottom: 15px;
    font-weight: 600;
}

.commitment-item p {
    color: #666;
    line-height: 1.6;
}

/* -------------------------------- */
/* Service Details Section */
/* -------------------------------- */
.service-details-section {
    padding: 80px 0;
    background-color: #ffffff;
}

.service-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 40px;
}

.service-detail-item {
    padding: 30px;
    background: #f8f9fa;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.service-detail-item:hover {
    background: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* -------------------------------- */
/* Contact Section */
/* -------------------------------- */
.contact-section {
    text-align: center;
    padding: 80px 0;
    background: linear-gradient(135deg, #00b6d9 0%, #0090aa 100%);
    color: #ffffff;
    position: relative;
}

.contact-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}

.contact-content {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.contact-content h2 {
    color: #ffffff;
    margin-bottom: 20px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.contact-content p {
    color: #ffffff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.highlighted-contact {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 20px 0;
    color: #ffffff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.contact-link {
    color: #ffffff;
    text-decoration: underline;
    transition: all 0.3s ease;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    font-weight: 700;
}

.contact-link:hover {
    color: #ffffff;
    opacity: 0.9;
    text-decoration: none;
}

.contact-logo {
    max-width: 200px;
    margin-bottom: 30px;
    filter: brightness(0) invert(1);
    position: relative;
    z-index: 1;
}

/* -------------------------------- */
/* CTA Section */
/* -------------------------------- */
.cta-section {
    text-align: center;
    padding: 60px 0;
    background: #f8f9fa;
}

.cta-content {
    max-width: 800px;
    margin: 0 auto;
}

.cta-button {
    display: inline-block;
    padding: 15px 40px;
    font-size: 1.2rem;
    color: #ffffff;
    background-color: #00b6d9;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 30px;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(0, 182, 217, 0.3);
}

.cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 182, 217, 0.4);
}

/* -------------------------------- */
/* Animations */
/* -------------------------------- */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* -------------------------------- */
/* Responsive Adjustments */
/* -------------------------------- */
@media (max-width: 768px) {
    .header-image-container {
        height: 100vh;
        min-height: 500px;
    }

    .header-title {
        font-size: 2.5rem;
    }

    .header-subtitle {
        font-size: 1.2rem;
    }

    .use-case {
        width: 100%;
    }

    .service-details-grid {
        grid-template-columns: 1fr;
    }

    .section-header h2 {
        font-size: 2rem;
    }

    .commitment-grid {
        grid-template-columns: 1fr;
    }
}
