.card-select-container {
  height: 9rem;
  position: relative;
  border-radius: $border-radius-lg * 1.4;
}

$card-select-background-primary: rgba(
  $color: $primary,
  $alpha: 0.25,
);

.card-select-container.is-selected {
  background-color: $card-select-background-primary;
}

$card-select-padding: 4px;

.card-select-inner-container {
  position: absolute;
  top: $card-select-padding;
  bottom: $card-select-padding;
  left: $card-select-padding;
  right: $card-select-padding;
}

.card-select-button {
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid $gray-400;
  border-radius: $border-radius-lg;
}

.card-select-button:hover {
  border-color: $dark !important;
}

.card-select-container.is-selected .card-select-button {
  border-color: $primary !important;
  border-width: 2px !important;
  // font-weight: bold;
}

// selectable

.selectable {
  padding: $card-select-padding;
  border-radius: $border-radius-lg * 1.4;
}

// .selectable:hover {
//   background-color: $light;
// }

.selectable.is-selected {
  background-color: $card-select-background-primary;
}

.selectable-inner {
  border: 1px solid $gray-200;
  border-radius: $border-radius-lg;
  background-color: $body-bg;
  padding: 1rem;
}

.selectable:hover .selectable-inner {
  border-width: 1px;
  border-color: $gray-600;
}

.selectable.is-selected .selectable-inner {
  border-color: $primary;
  border-width: 2px !important;
  padding: 15px;
}
